import { getFullUrl } from 'hubspot-url-utils';
import { stringify } from 'hub-http/helpers/params';
import PortalIdParser from 'PortalIdParser';
import enviro from 'enviro';
import { fetchPreviewDomain } from './api/previewDomainClient';

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'cont... Remove this comment to see the full error message
import { getIconUrl } from 'content-icons-ui/iconUrl';
import { ICON_SETS, DEFAULT_ICON_SET } from 'ContentUtils/constants/IconSets';
import Categories from './constants/Categories';
import DesignAssetTypes from './constants/DesignAssetTypes';
import DesignAssetSources from './constants/DesignAssetSources';
const SITE_PAGE_SUBCATEGORIES = ['site_page', 'mab_site_page_master', 'mab_site_page_variant'];
const isSitePage = subcategoryId => {
  return typeof subcategoryId === 'string' && SITE_PAGE_SUBCATEGORIES.includes(subcategoryId.toLowerCase());
};

// Used to allow returning to original page whne navigating to a settings page.
const ESHREF_PARAM = `eschref=${encodeURIComponent(window.location.pathname)}`;
const HTTPS_REGEX = /^https:\/\//g;
const Routes = {
  gates: [],
  optinMap: {},
  hasInitialized: false,
  previewDomainPromise: null,
  init(nextGates, optinMap = {}) {
    Routes.gates = nextGates;
    Routes.optinMap = optinMap;
    Routes.hasInitialized = true;
  },
  isUngated(gateName) {
    if (!Array.isArray(Routes.gates)) {
      throw new Error('Routes needs to be initialized with an array of gates before using');
    }
    return Routes.gates.indexOf(gateName) > -1;
  },
  isOptedIn(optinName) {
    if (!Routes.optinMap) {
      throw new Error('Initialized map with falsey value');
    }
    return !!Routes.optinMap[optinName];
  },
  dashboard(categoryId, subcategoryId, parentId) {
    const category = Categories[categoryId];
    let slug = category.api_slug;
    if (['site_page', 'landing_page', 'blog_post', 'blog_listing_page'].includes(category.field_name)) {
      if (category.field_name === 'site_page' || isSitePage(subcategoryId)) {
        return `/website/${PortalIdParser.get()}/pages/site`;
      } else if (category.field_name === 'landing_page') {
        return `/website/${PortalIdParser.get()}/pages/landing`;
      } else if (category.field_name === 'blog_post') {
        return Routes.blogDashboard(parentId);
      } else if (category.field_name === 'blog_listing_page') {
        return Routes.blogListingDashboard(parentId);
      }
      return `/website/${PortalIdParser.get()}`;
    }
    if (isSitePage(subcategoryId)) {
      slug = Categories.site_page.api_slug;
    }
    if (category.field_name === 'email') {
      return Routes.emailDashboard();
    }
    if (category.field_name === 'sms') {
      return Routes.smsDashboard();
    }
    if (category.field_name === 'web_interactive') {
      return `/calls-to-action/${PortalIdParser.get()}/manage`;
    }
    return `/content/${PortalIdParser.get()}/${slug}`;
  },
  blogImporter() {
    return `/content/${PortalIdParser.get()}/blog-import`;
  },
  siteImporter() {
    return `/site-importer/${PortalIdParser.get()}/content-import/dashboard`;
  },
  siteImporterCreate(importContentType = 'blog', importType, params) {
    const siteImporterWiz = `/site-importer/${PortalIdParser.get()}/content-import/wizard`;
    const paramString = params ? `?${stringify(params)}` : '';
    const normalizedImportType = importType && importType.toLowerCase();
    const normalizedImportContentType = importContentType.toLowerCase();
    const isBlogImporterRoute = normalizedImportContentType === 'blog' && normalizedImportType === 'xml';
    const isKnowledgeBaseInsightsRoute = normalizedImportContentType === 'knowledge_base' && normalizedImportType === 'crawl';
    if (isBlogImporterRoute) {
      return this.blogImporter();
    }
    if (isKnowledgeBaseInsightsRoute) {
      return `${this.knowledgeBaseInsights()}?loadImportModal=true`;
    }
    return importType ? `${siteImporterWiz}/new/${importContentType}/${importType}${paramString}` : `${siteImporterWiz}/import-type/${importContentType}${paramString}`;
  },
  aiWebsiteAssistantFlow(params) {
    // 'app' refers to an enum: the referring app's name mapped to a link text translaton in cms-onboarding-ui (defaults to 'Exit')
    const baseRoute = `/cms-onboarding/${PortalIdParser.get()}/ai-website-builder/flow`;
    return params ? `${baseRoute}?${stringify(params)}` : baseRoute;
  },
  aiLandingPagesFlow(params) {
    const baseRoute = `/cms-onboarding/${PortalIdParser.get()}/ai-landing-pages/flow`;
    return params ? `${baseRoute}?${stringify(params)}` : baseRoute;
  },
  emailDashboard() {
    return `/email/${PortalIdParser.get()}/manage`;
  },
  smsDashboard() {
    return `/sms/${PortalIdParser.get()}/views`;
  },
  emailFolder(folderId = '') {
    return `${Routes.emailDashboard()}/folder/${folderId}`;
  },
  edit(contentId, categoryId, section) {
    const route = Routes.inpageEdit(contentId, categoryId);
    return section ? `${route}/${section}` : route;
  },
  editDragDropEmail(contentId) {
    return Routes.editEmail(contentId);
  },
  editEmail(contentId) {
    return `/email/${PortalIdParser.get()}/edit/${contentId}`;
  },
  editSms(contentId) {
    return `/sms/${PortalIdParser.get()}/edit/${contentId}`;
  },
  editEmailAutomation(contentId) {
    return `/email/${PortalIdParser.get()}/edit/${contentId}/automation`;
  },
  emailContentCreator(params, templateFilter = 'all') {
    const route = `/content/${PortalIdParser.get()}/create/email/${templateFilter}`;
    return params ? `${route}?${stringify(params)}` : route;
  },
  podcastDashboard(contentId) {
    if (!contentId) {
      // podcast-management-ui will redirect to first show
      return `/podcasts/${PortalIdParser.get()}/show`;
    }
    return `/podcasts/${PortalIdParser.get()}/show/${contentId}/episodes`;
  },
  editPodcastEpisode(contentId) {
    return `/podcasts/${PortalIdParser.get()}/editor/${contentId}`;
  },
  podcastEpisodeDetails(episodeId) {
    return `/podcasts/${PortalIdParser.get()}/episode/${episodeId}`;
  },
  podcastGenerator(showId) {
    return showId ? `/podcasts/${PortalIdParser.get()}/editor/generate/show/${showId}/episode` : `/podcasts/${PortalIdParser.get()}/editor/generate`;
  },
  inpageEdit(contentId, categoryId) {
    if (!categoryId) {
      return `/pages/${PortalIdParser.get()}/editor/${contentId}`;
    }
    const category = Categories[categoryId] || {};
    switch (category.field_name) {
      case 'blog_post':
        return `/blog/${PortalIdParser.get()}/editor/${contentId}`;
      case 'email':
        return Routes.editEmail(contentId);
      case 'sms':
        return Routes.editSms(contentId);
      case 'web_interactive':
        return `/calls-to-action/${PortalIdParser.get()}/edit/${contentId}`;
      case 'podcast':
        return Routes.podcastDashboard(contentId);
      case 'podcast_episode':
        return Routes.editPodcastEpisode(contentId);
      case 'knowledge_article':
        return `/knowledge-article/${PortalIdParser.get()}/editor/${contentId}`;
      case 'case_study_instance_layout':
        return `/case-study/${PortalIdParser.get()}/editor/${contentId}`;
      default:
        return `/pages/${PortalIdParser.get()}/editor/${contentId}`;
    }
  },
  details(contentId, categoryId, subcategory, params = null, {
    tab = 'performance'
  } = {
    tab: 'performance'
  }) {
    const urlParamsString = params ? `?${stringify(params)}` : '';
    const category = Categories[categoryId];
    if (category.field_name === 'unmapped') {
      return `/content-detail/${PortalIdParser.get()}/external-page/optimization${urlParamsString}`;
    } else if (['site_page', 'landing_page', 'blog_post', 'blog_listing_page'].includes(category.field_name)) {
      const slugSource = isSitePage(subcategory) ? Categories['site_page'] : category;
      return `/content-detail/${PortalIdParser.get()}/${slugSource.slug_singular}/${contentId}/${tab}${urlParamsString}`;
    } else if (category.field_name === 'email') {
      return `/email/${PortalIdParser.get()}/details/${contentId}/${tab}${urlParamsString}`;
    } else if (category.field_name === 'sms') {
      return `/sms/${PortalIdParser.get()}/details/${contentId}/${tab}${urlParamsString}`;
    } else if (category.field_name === 'knowledge_article') {
      return `/knowledge/${PortalIdParser.get()}/insights/article/${contentId}/${tab}${urlParamsString}`;
    } else if (category.field_name === 'web_interactive') {
      return `/calls-to-action/${PortalIdParser.get()}/details/${contentId}/${tab}${urlParamsString}`;
    }
    return `/content-detail/${PortalIdParser.get()}/landing-page/${contentId}/${tab}${urlParamsString}`;
  },
  staging() {
    return `/content/${PortalIdParser.get()}/staging`;
  },
  stagingBeta() {
    return Routes.staging();
  },
  cta() {
    return `/cta/${PortalIdParser.get()}`;
  },
  ctaDetails(placementGuid) {
    return `/cta/${PortalIdParser.get()}/${placementGuid}`;
  },
  ctaSmartRules(placementGuid) {
    return `/content/${PortalIdParser.get()}/cta/${placementGuid}`;
  },
  templateSwap(categoryId, contentId) {
    if (!contentId) {
      throw new Error('A content id is required for template swap');
    }
    const category = Categories[categoryId];
    if (category.slug_singular === 'site-page' || category.slug_singular === 'landing-page' || category.slug_singular === 'blog-listing-page') {
      return `/active-theme-create/${PortalIdParser.get()}/edit-page/${category.slug_singular}/select-template?contentId=${contentId}&referrer=pageSettings`;
    }
    throw new Error('Themed template swap can only used for pages');
  },
  create(categoryId, subcategory, {
    isMarketingStarterPortal = false,
    hasThemedContentCreatorAccess = false,
    moduleIdForReviewingDependents = null,
    moduleName = '',
    useActiveThemeContentCreator = false,
    activeThemeContentCreatorParams = {
      pageName: null
    }
  } = {}) {
    const category = isSitePage(subcategory) ? Categories.site_page : Categories[categoryId];
    if (category.slug_singular === 'blog-post') {
      throw new Error('Create page only used for pages & emails');
    }
    if (category.field_name === 'web_interactive') {
      return `/calls-to-action/${PortalIdParser.get()}/create`;
    }
    if (category.slug_singular !== 'email' && useActiveThemeContentCreator && activeThemeContentCreatorParams.pageName) {
      // active-theme-content-creator requires a pageName defined in query params, otherwise page creation will fail.
      return `/active-theme-create/${PortalIdParser.get()}/create-page/${category.slug_singular}/select-theme?${stringify(activeThemeContentCreatorParams)}`;
    }
    if (category.slug_singular !== 'email' && hasThemedContentCreatorAccess) {
      return `/active-theme-create/${PortalIdParser.get()}/create-page/${category.slug_singular}/select-template`;
    }
    const createUrl = category.slug_singular !== 'email' ? `/active-theme-create/${PortalIdParser.get()}/create-page/${category.slug_singular}/select-template` : `/content/${PortalIdParser.get()}/create/${category.slug_singular}`;
    if (category.slug_singular !== 'email' && isMarketingStarterPortal) {
      return `${createUrl}/starter`;
    }
    if (category.slug_singular === 'email') {
      if (moduleIdForReviewingDependents) {
        return `${createUrl}?subcategory=drag_drop_email&moduleId=${moduleIdForReviewingDependents}&moduleName=${moduleName}`;
      } else if (subcategory === 'drag_drop_email') {
        return `${createUrl}?subcategory=drag_drop_email`;
      }
    }
    return createUrl;
  },
  createNew(categoryId, subcategory, name, templatePath, businessUnitId) {
    /* Create new only available in create-beta */
    const portalId = PortalIdParser.get();
    const category = subcategory === 'landing_page' ? Categories.landing_page : Categories[categoryId];
    if (category.slug_singular === 'blog-post') {
      throw new Error('Create page only used for pages & emails');
    }
    const mayBeAddBusinessUnitId = businessUnitId ? `&businessUnitId=${businessUnitId}` : '';
    return `/content/${portalId}/create/${category.slug_singular}/new?name=${name}&templatePath=${templatePath}${mayBeAddBusinessUnitId}`;
  },
  settings(subsection, tab) {
    const portalId = PortalIdParser.get();
    const baseURL = `/settings/${portalId}/`;
    switch (subsection) {
      case 'email':
        {
          const emailBase = `${baseURL}marketing/email/`;
          switch (tab) {
            case 'frequency':
              return `${emailBase}frequency`;
            case 'subscriptions':
              return `${emailBase}subscriptions`;
            case 'types':
              return `${emailBase}types`;
            case 'tracking':
              return `${emailBase}tracking`;
            case 'double-opt-in':
              return `${emailBase}double-opt-in`;
            case 'preferences-center':
              return `${emailBase}preferences-center`;
            case 'doi':
              return `${emailBase}doi`;
            case 'footer':
              return `${emailBase}configuration/footer`;
            case 'personalization':
              return `${emailBase}configuration/personalization`;
            case 'branding':
              return `${emailBase}configuration/branding`;
            case 'rss':
              return `${emailBase}configuration/rss`;
            case 'approvals':
              return `${emailBase}configuration/approvals`;
            default:
              return emailBase;
          }
        }
      case 'sms':
        {
          return `${baseURL}messaging/sms`;
        }
      case 'blog':
        {
          return `${baseURL}website/blogs${tab ? `/${tab}` : ''}`;
        }
      case 'publishing':
        {
          return `${baseURL}website/pages`;
        }
      case 'users':
        return `${baseURL}users`;
      case 'domains':
        {
          if (tab === 'languageSettings') {
            return `${baseURL}marketing/domains/languageSettings`;
          }
          return `/domains/${portalId}`;
        }
      case 'themes':
        {
          if (Routes.isUngated('CMS:BrandingSettingsUploadTheme')) {
            return `${baseURL}account-defaults/branding/themes`;
          }
          return `${baseURL}website/themes`;
        }
      case 'ai-settings':
        {
          return `/ai-settings/${portalId}`;
        }
      case 'podcasts':
        return `${baseURL}podcasts`;
      default:
        return baseURL;
    }
  },
  podcastShowSettings(podcastShowId) {
    if (!podcastShowId) {
      // Redirect to first show
      return `${Routes.settings('podcasts')}/shows`;
    }
    return `${Routes.settings('podcasts')}/shows/${podcastShowId}`;
  },
  privateContentSettings(tab = 'basics', domain = 'all-domains') {
    return `/settings/${PortalIdParser.get()}/content-membership/${domain}/${tab}`;
  },
  accessGroups(tab = 'members') {
    return `/memberships/${PortalIdParser.get()}/${tab}`;
  },
  systemPagesSettings(domain) {
    const basePagesUrl = Routes.settings('publishing');
    const __domain = domain || `all-domains`;
    return `${basePagesUrl}/${__domain}/system-pages?${ESHREF_PARAM}`;
  },
  logoSettings(domain) {
    const portalId = PortalIdParser.get();
    const __domain = domain || `all-domains`;
    return `/settings/${portalId}/website/pages/${__domain}/branding?${ESHREF_PARAM}`;
  },
  logoBrandSettings(brandKitId) {
    const portalId = PortalIdParser.get();
    return `/settings/${portalId}/account-defaults/branding/brand-kit/${brandKitId}/logo?${ESHREF_PARAM}`;
  },
  brandSettings(brandKitId, brandKitTab) {
    const portalId = PortalIdParser.get();
    let url = brandKitId ? `/settings/${portalId}/account-defaults/branding/brand-kit/${brandKitId}` : `/settings/${portalId}/account-defaults/branding`;
    if (brandKitTab) {
      url += `/${brandKitTab}`;
    }
    return url;
  },
  blogSpecificSettings(blogId, subTab = '') {
    return `${Routes.settings('blog')}/${blogId}${subTab}?${ESHREF_PARAM}`;
  },
  advancedMenuSettings(menuId, redirectUrl) {
    const basePath = `/menus/${PortalIdParser.get()}/edit/${menuId}`;
    if (redirectUrl) {
      return `${basePath}?redirectUrl=${encodeURIComponent(redirectUrl)}`;
    }
    return basePath;
  },
  designManager(assetId, assetType, assetSource, path, queryParams) {
    let route = `/design-manager/${PortalIdParser.get()}`;
    if (assetId && assetType) {
      let urlPath;
      switch (assetType) {
        case DesignAssetTypes.LAYOUT:
        case DesignAssetTypes.GLOBAL_GROUP:
          {
            urlPath = `templates/${assetId}`;
            break;
          }
        case DesignAssetTypes.TEMPLATE:
          {
            if (assetSource === DesignAssetSources.HOLLOW) {
              urlPath = `code/p/${path}`;
            } else {
              urlPath = `code/${assetId}`;
            }
            break;
          }
        case DesignAssetTypes.CUSTOM_WIDGET:
          {
            if (assetSource === DesignAssetSources.HOLLOW) {
              urlPath = `modules/p/${assetId}`;
            } else {
              urlPath = `modules/${assetId}`;
            }
            break;
          }
        case DesignAssetTypes.GLOBAL_WIDGET:
          {
            urlPath = `global-modules/${assetId}`;
            break;
          }
        default:
          {
            throw new Error(`Invalid Design Manager assetType provided. Received '${assetType}'.`);
          }
      }
      route = `${route}/${urlPath}${queryParams ? `?${stringify(queryParams)}` : ''}`;
    }
    return `${route}${queryParams ? `?${stringify(queryParams)}` : ''}`;
  },
  designPreviewer(assetId, assetType, assetSource, path) {
    let route = `/design-previewer/${PortalIdParser.get()}`;
    if (assetId && assetType) {
      let urlPath;
      switch (assetType) {
        case DesignAssetTypes.LAYOUT:
        case DesignAssetTypes.GLOBAL_GROUP:
          {
            urlPath = `templates/${assetId}`;
            break;
          }
        case DesignAssetTypes.TEMPLATE:
          {
            if (assetSource === DesignAssetSources.HOLLOW) {
              urlPath = `code/p/${path}`;
            } else {
              urlPath = `code/${assetId}`;
            }
            break;
          }
        case DesignAssetTypes.CUSTOM_WIDGET:
          {
            if (assetSource === DesignAssetSources.HOLLOW) {
              urlPath = `modules/p/${assetId}`;
            } else {
              urlPath = `modules/${assetId}`;
            }
            break;
          }
        default:
          {
            throw new Error(`Invalid Design Previewer assetType provided. Received '${assetType}'.`);
          }
      }
      route = `${route}/${urlPath}`;
    }
    return route;
  },
  globalContent(moduleId) {
    if (moduleId) {
      return Routes.designManager(moduleId, DesignAssetTypes.GLOBAL_WIDGET);
    }
    return `${Routes.designManager()}?referrer=global-content`;
  },
  fileManager(folderPath, params) {
    let path = `/files/${PortalIdParser.get()}`;
    if (folderPath) {
      path = `${path}/folder/${folderPath.replace(/^\//, '')}`;
    }
    if (params) {
      path = `${path}?${stringify(params)}`;
    }
    return path;
  },
  hubdb() {
    return `/hubdb/${PortalIdParser.get()}`;
  },
  hubdbTable(tableId) {
    return `${Routes.hubdb()}/table/${tableId}`;
  },
  workflows(workflowId, workflowType = 'WORKFLOWS') {
    if (workflowType === 'AUTOMATION_PLATFORM' || workflowType === 'PLATFORM') {
      return `/workflows/${PortalIdParser.get()}/platform/flow/${workflowId}/edit`;
    }
    return `/contacts/${PortalIdParser.get()}/automation/flow/${workflowId}/edit/`;
  },
  workflowsDashboard() {
    return `/workflows/${PortalIdParser.get()}`;
  },
  workflowPerformance(workflowId) {
    return `/workflows/${PortalIdParser.get()}/details/${workflowId}/performance`;
  },
  newWorkflow() {
    return `/workflows/${PortalIdParser.get()}/platform/create/new`;
  },
  contact(vid) {
    return `/contacts/${PortalIdParser.get()}/contact/${vid}`;
  },
  deal(dealId) {
    return `/contacts/${PortalIdParser.get()}/deal/${dealId}`;
  },
  graphiQl() {
    return `/graphiql/${PortalIdParser.get()}`;
  },
  forms(formId, redirectUrl, extraParams = {}, objectTypeId, name) {
    const basePath = `/forms/${PortalIdParser.get()}`;
    if (!formId) {
      return basePath;
    }
    const baseEditorPath = `${basePath}/editor/${formId}/edit/form`;
    const fieldPath = objectTypeId && name ? `/field/${objectTypeId}%2F${name}` : '';
    let params = Object.assign({}, extraParams);
    if (redirectUrl) {
      params = Object.assign({
        redirectUrl,
        isExternalEditor: extraParams.isExternalEditor || 'cos'
      }, params);
    }
    const paramsAsString = stringify(params);
    return paramsAsString.length > 0 ? `${baseEditorPath}${fieldPath}?${paramsAsString}` : baseEditorPath;
  },
  newForm() {
    return `/forms/${PortalIdParser.get()}/type`;
  },
  feedbackSurveys(feedbackSurveyId, surveyType) {
    return `/feedback-editor/${PortalIdParser.get()}/${surveyType}/${feedbackSurveyId}`;
  },
  formSubmissions(formId, redirectUrl) {
    const basePath = `/forms/${PortalIdParser.get()}/${formId}/submissions`;
    const params = Object.assign({}, redirectUrl && {
      redirectUrl
    });
    const paramsAsString = stringify(params);
    return paramsAsString.length > 0 ? `${basePath}?${paramsAsString}` : basePath;
  },
  formsDashboard() {
    return `/forms/${PortalIdParser.get()}`;
  },
  formsEditor2({
    formId
  }) {
    return `/forms/${PortalIdParser.get()}/new-editor/${formId}`;
  },
  salesforceCampaigns() {
    return `/integrations-beta/${PortalIdParser.get()}/your-integrations/salesforce`;
  },
  domainManager() {
    return `/domains/${PortalIdParser.get()}`;
  },
  leadFlowFollowup(leadFlowId) {
    return `/lead-flows/${PortalIdParser.get()}/edit/${leadFlowId}/follow-up`;
  },
  formFollowup(formId) {
    return `/forms/${PortalIdParser.get()}/editor/${formId}/edit/followUp`;
  },
  formAutomation(formId) {
    return `/forms/${PortalIdParser.get()}/editor/${formId}/edit/automation`;
  },
  emailCompare(ids = []) {
    if (ids.length > 0) {
      return `/email/${PortalIdParser.get()}/compare?id=${ids.join('&id=')}`;
    }
    return `/email/${PortalIdParser.get()}/compare`;
  },
  surveyEditor(surveyType, surveyId) {
    const surveyTypePath = {
      CES: 'support',
      NPS: 'loyalty',
      CSAT: 'satisfactions'
    }[surveyType];
    return `/feedback-editor/${PortalIdParser.get()}/${surveyTypePath}/${surveyId}`;
  },
  cmsHubPricingPage() {
    return `/pricing/${PortalIdParser.get()}/cms`;
  },
  marketingHubPricingPage() {
    return `/pricing/${PortalIdParser.get()}/products/marketing`;
  },
  compareMarketingProducts(product) {
    const portalId = PortalIdParser.get();
    let queryString = '';
    if (product) {
      queryString = `?product=${product}`;
    }
    return `/browse/${portalId}/compare/marketing${queryString}`;
  },
  accountSummary() {
    const portalId = PortalIdParser.get();
    return `/account-and-billing/${portalId}/products`;
  },
  accountUsage() {
    const portalId = PortalIdParser.get();
    return `/account-and-billing/${portalId}/usage`;
  },
  additionalContact(queryParams = '') {
    const portalId = PortalIdParser.get();
    return `/pricing/${portalId}/confirm-contacts${queryParams}`;
  },
  campaignDetails(campaignId) {
    const portalId = PortalIdParser.get();
    return `/campaigns/${portalId}/${campaignId}`;
  },
  goToWebinar() {
    return `/integrations-beta/${PortalIdParser.get()}/your-integrations/gotowebinar`;
  },
  list(listId) {
    return `/contacts/${PortalIdParser.get()}/lists/${listId}`;
  },
  ilsList(listId) {
    return `/contacts/${PortalIdParser.get()}/objectLists/${listId}`;
  },
  lists() {
    // Not lists UI but CRM contact filter view
    return `/contacts/${PortalIdParser.get()}/contacts/list/view/all`;
  },
  newList() {
    return `/contacts/${PortalIdParser.get()}/objectLists/create`;
  },
  getLocalPreviewDomain() {
    try {
      return localStorage.getItem('PREVIEW_DOMAIN') || getFullUrl('local', {
        envOverride: 'qa'
      }).replace(HTTPS_REGEX, '');
    } catch (e) {
      return getFullUrl('local', {
        envOverride: 'qa'
      }).replace(HTTPS_REGEX, '');
    }
  },
  // Deprecated
  // Use async methods going forward to get the correct domain
  getPreviewDomain() {
    const env = enviro.getShort('preview');
    if (env !== 'local') {
      return `${PortalIdParser.get()}.hubspotpreview${env === 'qa' ? env : ''}-${enviro.getHublet()}.com`;
    }
    return Routes.getLocalPreviewDomain();
  },
  /**
   * Returns an async promise for the current preview domain.
   *
   * Before using this utility, you'll need to ensure your users are covered by one of the scopes
   * on this endpoint:
   * https://git.hubteam.com/HubSpot/cos-renderer/blob/master/ContentRenderingApiWeb/src/main/java/com/hubspot/content/rendering/resources/InternalPreviewDomainResource.java
   *
   * If not, let us know and we'll get you added.
   *
   * Need this as early as possible? Use quick-fetch! Add the following to your
   * earlyRequester:
   *
   * window.quickFetch.makeEarlyRequest('early-preview-domain', {
   *   url: window.quickFetch.getApiUrl(
   *     '/cos-domains/v1/internal/preview-domain',
   *     true
   *   ),
   *   dataType: 'json',
   *   type: 'GET',
   * });
   *
   * @returns a promise whose result is of the form { previewDomain: "1234.hubspotpreview-hublet.com" }
   */
  getPreviewDomainPromise() {
    if (Routes.previewDomainPromise) {
      return Routes.previewDomainPromise;
    }
    const env = enviro.getShort('preview');
    if (env !== 'local') {
      Routes.previewDomainPromise = fetchPreviewDomain();
    } else {
      Routes.previewDomainPromise = Promise.resolve({
        previewDomain: Routes.getLocalPreviewDomain()
      });
    }
    return Routes.previewDomainPromise;
  },
  // Use async methods going forward to get the correct domain
  // OR provide domain yourself
  getPreviewUrl(contentId, extraParams, previewDomain) {
    const env = enviro.getShort('preview');
    const params = Object.assign({
      portalId: PortalIdParser.get(),
      _preview: true,
      cacheBust: Date.now()
    }, extraParams);
    previewDomain = previewDomain || Routes.getPreviewDomain();
    if (env === 'local') {
      return `https://${previewDomain}/cos-rendering/v1/public/_hcms/preview/content/${contentId}?${stringify(params)}`;
    }
    return `https://${previewDomain}/_hcms/preview/content/${contentId}?${stringify(params)}`;
  },
  getPreviewUrlPromise(contentId, extraParams) {
    return Routes.getPreviewDomainPromise().then(({
      previewDomain
    }) => Routes.getPreviewUrl(contentId, extraParams, previewDomain));
  },
  getWebInteractivePreviewUrl(previewInteractiveId, pageContext, previewDomain) {
    const params = {
      previewInteractiveId,
      _preview: true,
      portalId: PortalIdParser.get(),
      preview_web_interactive: true
    };
    previewDomain = previewDomain || Routes.getPreviewDomain();
    if (pageContext.id) {
      return `https://${previewDomain}/_hcms/preview/content/${pageContext.id}?${stringify(Object.assign({}, params, {
        preview_key: pageContext.previewKey
      }))}`;
    }
    return `https://${previewDomain}/_hcms/preview/preview-interactive?${stringify(params)}`;
  },
  getVideoPreviewUrl(previewDomain, playerId, extraParams) {
    const params = Object.assign({
      portalId: PortalIdParser.get()
    }, extraParams);
    previewDomain = previewDomain || Routes.getPreviewDomain();
    return `https://${previewDomain}/content-rendering/v1/internal/preview/player/${playerId}?${stringify(params)}`;
  },
  getVideo2PreviewUrl(videoId, extraParams, {
    envOverride,
    hubletOverride,
    usePublicDomain = false
  } = {}) {
    const params = Object.assign({}, extraParams, {
      hostApp: this.getCurrentApp()
    });
    let queryString = stringify(params);
    if (queryString.length) {
      queryString = `?${queryString}`;
    }
    // certain situations like editor previews may not prevent iframe-ing app subdomain due to CSP headers
    if (usePublicDomain || window.hsInEditor) {
      const subdomain = enviro.getShort('video_player') === 'local' ? 'local' : 'play';
      return `${getFullUrl(subdomain, {
        domainOverride: 'hubspotvideo',
        envOverride,
        hubletOverride
      })}/v/${PortalIdParser.get()}/id/${videoId}${queryString}`;
    } else {
      const subdomain = enviro.getShort('video_player') === 'local' ? 'local' : 'app';
      return `${getFullUrl(subdomain, {
        envOverride,
        hubletOverride
      })}/video/${PortalIdParser.get()}/id/${videoId}${queryString}`;
    }
  },
  themes() {
    return `${Routes.settings('themes')}?${ESHREF_PARAM}`;
  },
  themeEditor(themePath, params) {
    let route = `/theme-editor/${PortalIdParser.get()}/edit/${themePath}`;
    if (params) route = `${route}?${stringify(params)}`;
    return route;
  },
  globalContentEditor(globalContentId, params) {
    const {
      assetPath
    } = params || {};
    if (!(globalContentId || assetPath)) {
      throw new Error('Global content ID or asset path required to load editor.');
    }
    let route = `/global-content/${PortalIdParser.get()}/editor`;
    if (globalContentId) route = `${route}/${globalContentId}/content`;
    if (params) route = `${route}?${stringify(params)}`;
    return route;
  },
  contentEmbedEditor(embedId, params) {
    let route = `/embeds/${PortalIdParser.get()}/editor/${embedId}/content`;
    if (params) route = `${route}?${stringify(params)}`;
    return route;
  },
  createMeeting() {
    return `/meetings/${PortalIdParser.get()}/link/new`;
  },
  editMeeting(portalId, meetingId) {
    return `/meetings/${portalId}/personal/${meetingId}`;
  },
  getContentIconsUrl(iconSet, iconName, style) {
    let iconSetToUse = '';
    if (iconSet && ICON_SETS[iconSet]) {
      iconSetToUse = iconSet;
    } else {
      iconSetToUse = DEFAULT_ICON_SET;
    }
    return getIconUrl(iconSetToUse, iconName, style);
  },
  blogDashboard(contentGroupId) {
    const baseUrl = `/website/${PortalIdParser.get()}/blog/posts`;
    return contentGroupId ? `${baseUrl}?blog=${contentGroupId}` : baseUrl;
  },
  blogListingDashboard(contentGroupId) {
    const baseUrl = `/website/${PortalIdParser.get()}/blog/listing`;
    return contentGroupId ? `${baseUrl}?blog=${contentGroupId}` : baseUrl;
  },
  accountDefaults(path) {
    return `/settings/${PortalIdParser.get()}/account-defaults/${path}`;
  },
  knowledgeBaseInsights() {
    return `/knowledge/${PortalIdParser.get()}/insights/dashboard`;
  },
  knowledgeBaseArticles(stateFilter = 'ALL', knowledgeBaseId) {
    return knowledgeBaseId ? `/knowledge/${PortalIdParser.get()}/${knowledgeBaseId}/articles/state/${stateFilter}` : `/knowledge/${PortalIdParser.get()}/articles/state/${stateFilter}`;
  },
  quoteTemplate(quoteTemplateId) {
    return `/settings/${PortalIdParser.get()}/sales/quotes/quote-templates/edit/${quoteTemplateId}`;
  },
  productsLibrary() {
    return `/contacts/${PortalIdParser.get()}/objects/0-7/views/all/list`;
  },
  manageCurrencies() {
    return `/settings/${PortalIdParser.get()}/account-defaults/multicurrency`;
  },
  paymentLinks() {
    return `/payment-links/${PortalIdParser.get()}`;
  },
  payments() {
    return `/settings/${PortalIdParser.get()}/sales/payments`;
  },
  paymentsGuide() {
    return `/commerce/${PortalIdParser.get()}`;
  },
  academy(path) {
    return `/academy/${PortalIdParser.get()}/tracks/${path}`;
  },
  getCurrentApp() {
    return window.hubspot && window.hubspot.bender && window.hubspot.bender.currentProject;
  },
  liveMessages() {
    return `/live-messages/${PortalIdParser.get()}/inbox`;
  },
  menuDraftPageLink(contentType, contentId) {
    switch (contentType) {
      case `knowledge_article`:
        return `/knowledge-article/${PortalIdParser.get()}/editor/${contentId}/content`;
      default:
        return `/pages/${PortalIdParser.get()}/editor/${contentId}`;
    }
  },
  inboxSettings() {
    return `/live-messages-settings/${PortalIdParser.get()}/inboxes`;
  },
  marketplaceDownloads() {
    return `/marketplace-settings/${PortalIdParser.get()}/downloads`;
  }
};
export const init = Routes.init;
export const isUngated = Routes.isUngated;
export const isOptedIn = Routes.isOptedIn;
export const dashboard = Routes.dashboard;
export const blogImporter = Routes.blogImporter;
export const siteImporter = Routes.siteImporter;
export const siteImporterCreate = Routes.siteImporterCreate;
export const aiWebsiteAssistantFlow = Routes.aiWebsiteAssistantFlow;
export const emailDashboard = Routes.emailDashboard;
export const edit = Routes.edit;
export const editDragDropEmail = Routes.editDragDropEmail;
export const editEmail = Routes.editEmail;
export const inpageEdit = Routes.inpageEdit;
export const details = Routes.details;
export const staging = Routes.staging;
export const stagingBeta = Routes.stagingBeta;
export const cta = Routes.cta;
export const ctaDetails = Routes.ctaDetails;
export const ctaSmartRules = Routes.ctaSmartRules;
export const templateSwap = Routes.templateSwap;
export const create = Routes.create;
export const createNew = Routes.createNew;
export const settings = Routes.settings;
export const accessGroups = Routes.accessGroups;
export const privateContentSettings = Routes.privateContentSettings;
export const systemPagesSettings = Routes.systemPagesSettings;
export const logoSettings = Routes.logoSettings;
export const blogSpecificSettings = Routes.blogSpecificSettings;
export const advancedMenuSettings = Routes.advancedMenuSettings;
export const designManager = Routes.designManager;
export const designPreviewer = Routes.designPreviewer;
export const globalContent = Routes.globalContent;
export const fileManager = Routes.fileManager;
export const hubdb = Routes.hubdb;
export const hubdbTable = Routes.hubdbTable;
export const workflows = Routes.workflows;
export const workflowsDashboard = Routes.workflowsDashboard;
export const contact = Routes.contact;
export const deal = Routes.deal;
export const forms = Routes.forms;
export const salesforceCampaigns = Routes.salesforceCampaigns;
export const domainManager = Routes.domainManager;
export const leadFlowFollowup = Routes.leadFlowFollowup;
export const formFollowup = Routes.formFollowup;
export const emailCompare = Routes.emailCompare;
export const surveyEditor = Routes.surveyEditor;
export const cmsHubPricingPage = Routes.cmsHubPricingPage;
export const marketingHubPricingPage = Routes.marketingHubPricingPage;
export const compareMarketingProducts = Routes.compareMarketingProducts;
export const accountSummary = Routes.accountSummary;
export const accountUsage = Routes.accountUsage;
export const additionalContact = Routes.additionalContact;
export const campaignDetails = Routes.campaignDetails;
export const goToWebinar = Routes.goToWebinar;
export const list = Routes.list;
export const ilsList = Routes.ilsList;
export const lists = Routes.lists;
export const newList = Routes.newList;
export const getPreviewDomain = Routes.getPreviewDomain;
export const getPreviewUrl = Routes.getPreviewUrl;
export const getVideoPreviewUrl = Routes.getVideoPreviewUrl;
export const themes = Routes.themes;
export const themeEditor = Routes.themeEditor;
export const globalContentEditor = Routes.globalContentEditor;
export const contentEmbedEditor = Routes.contentEmbedEditor;
export const createMeeting = Routes.createMeeting;
export const editMeeting = Routes.editMeeting;
export const getContentIconsUrl = Routes.getContentIconsUrl;
export const blogDashboard = Routes.blogDashboard;
export const blogListingDashboard = Routes.blogListingDashboard;
export const accountDefaults = Routes.accountDefaults;
export const knowledgeBaseInsights = Routes.knowledgeBaseInsights;
export const knowledgeBaseArticles = Routes.knowledgeBaseArticles;
export const scalableGlobalContentEditor = Routes.globalContentEditor;
export const academy = Routes.academy;
export const liveMessages = Routes.liveMessages;
export const menuDraftPageLink = Routes.menuDraftPageLink;
export const inboxSettings = Routes.inboxSettings;
export const podcastDashboard = Routes.podcastDashboard;
export const editPodcastEpisode = Routes.editPodcastEpisode;
export const podcastShowSettings = Routes.podcastShowSettings;
export const podcastEpisodeDetails = Routes.podcastEpisodeDetails;
export const podcastGenerator = Routes.podcastGenerator;
export const marketplaceDownloads = Routes.marketplaceDownloads;
export default Routes;