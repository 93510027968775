import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data"],
  _excluded2 = ["data"],
  _excluded3 = ["data"],
  _excluded4 = ["data"],
  _excluded5 = ["pageLength", "offset"];
import { registerQuery, useLazyQuery, useQuery } from 'data-fetching-client';
import { fetchAllAssignedTasks, fetchAssignedTasks, fetchEligibleUsersForTask, hasEligibleUsers, searchEligibleUsers } from '../../api/assignedTasksApi';
export const ASSIGNED_TASKS_QUERY = registerQuery({
  fieldName: 'assignedTasks',
  fetcher: () => fetchAssignedTasks()
});
export const useAssignedTasks = () => {
  var _data$assignedTasks;
  const _useQuery = useQuery(ASSIGNED_TASKS_QUERY),
    {
      data
    } = _useQuery,
    rest = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  return Object.assign({
    assignedOnboardingTasks: data === null || data === void 0 || (_data$assignedTasks = data.assignedTasks) === null || _data$assignedTasks === void 0 ? void 0 : _data$assignedTasks.assignedOnboardingTasks
  }, rest);
};
export const ALL_ASSIGNED_TASKS_QUERY = registerQuery({
  fieldName: 'allAssignedTasks',
  fetcher: () => fetchAllAssignedTasks()
});
export const useAllAssignedTasks = () => {
  var _data$allAssignedTask;
  const _useQuery2 = useQuery(ALL_ASSIGNED_TASKS_QUERY),
    {
      data
    } = _useQuery2,
    rest = _objectWithoutPropertiesLoose(_useQuery2, _excluded2);
  return Object.assign({
    allAssignedTasks: data === null || data === void 0 || (_data$allAssignedTask = data.allAssignedTasks) === null || _data$allAssignedTask === void 0 ? void 0 : _data$allAssignedTask.assignedOnboardingTasks
  }, rest);
};
export const ELIGIBLE_USERS_QUERY = registerQuery({
  fieldName: 'eligibleUsers',
  args: ['taskId'],
  fetcher: ({
    taskId
  }) => fetchEligibleUsersForTask({
    taskId
  })
});

/**
 * @deprecated Use useHasEligibleUsers and useSearchEligibleUsers instead
 */
export const useEligibleUsersForTask = queryOptions => {
  var _data$eligibleUsers;
  const _useQuery3 = useQuery(ELIGIBLE_USERS_QUERY, queryOptions),
    {
      data
    } = _useQuery3,
    rest = _objectWithoutPropertiesLoose(_useQuery3, _excluded3);
  return Object.assign({
    eligibleUsers: data === null || data === void 0 || (_data$eligibleUsers = data.eligibleUsers) === null || _data$eligibleUsers === void 0 ? void 0 : _data$eligibleUsers.eligibleUsers
  }, rest);
};
const HAS_ELIGIBLE_USERS_QUERY = registerQuery({
  fieldName: 'hasEligibleUsers',
  args: ['taskId'],
  fetcher: ({
    taskId
  }) => hasEligibleUsers({
    taskId
  })
});
export const useHasEligibleUsers = queryOptions => {
  var _data$hasEligibleUser;
  const _useQuery4 = useQuery(HAS_ELIGIBLE_USERS_QUERY, queryOptions),
    {
      data
    } = _useQuery4,
    rest = _objectWithoutPropertiesLoose(_useQuery4, _excluded4);
  return Object.assign({
    hasEligibleUsers: data === null || data === void 0 || (_data$hasEligibleUser = data.hasEligibleUsers) === null || _data$hasEligibleUser === void 0 ? void 0 : _data$hasEligibleUser.hasEligibleUsers
  }, rest);
};
export const SEARCH_ELIGIBLE_USERS_QUERY = registerQuery({
  fieldName: 'searchEligibleUsers',
  args: ['taskId', 'searchQuery', 'pageLength', 'offset'],
  fetcher: _ref => {
    let {
        pageLength = 30,
        offset = 0
      } = _ref,
      variables = _objectWithoutPropertiesLoose(_ref, _excluded5);
    return searchEligibleUsers(Object.assign({
      pageLength,
      offset: {
        offset
      }
    }, variables));
  }
});
export const useSearchEligibleUsers = variables => {
  return useLazyQuery(SEARCH_ELIGIBLE_USERS_QUERY, {
    variables
  });
};